import { List, P, Small, Strong } from '@ahmdigital/ui';
import React from 'react';

export default {
  intro: {
    description: (
      <>
        <P>
          For those who like to stay savvy at tax time, by holding this hospital cover, you may be exempt from paying
          the Medicare Levy Surcharge~. And get peace of mind with Medically Necessary Ambulance cover, which means
          we’ll help with the bill for ambulance fees when you medically need to be transported to the nearest hospital.
          Features:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Unlimited Medically Necessary Ambulance^</Strong>
          </li>
          <li>
            <Strong>No excess for dependents</Strong>
          </li>
        </List>
      </>
    ),
    disclaimer: (
      <>
        <Small>
          ~If you earn over $97K or $194K as a single parent, couple or family, you could save on tax with hospital
          cover. For single parents, couples and families, the income thresholds increase by $1,500 for each MLS
          dependant child after the first. ‘Income’ and ‘dependant’ have specific meanings for MLS purposes. Find out
          more at the ATO website.
        </Small>
        <br />
        <Small>
          ^Transport to the nearest hospital provided by an ahm approved road, sea or air Ambulance provider that’s able
          to provide the level of care you medically need. Some ahm products limit the number of Ambulance services
          we’ll pay benefits towards per financial year. Check your product information to see if any limits apply. Your
          Daily Charge and/or Excess doesn’t apply to Ambulance claims.
        </Small>
      </>
    ),
  },
};
