import { gql } from '@apollo/client';

import { GRAPHQL } from '../../../cms/constants';

const FEATURES_AND_BENEFITS_FRAGMENT = `
  featuresAndBenefits {
    whatsIncluded {
      title: name
      footnoteLinkSymbol
      descriptionRaw
      callout
    }
    addOns {
      title: name
      footnoteLinkSymbol
      descriptionRaw
      callout
    }
    urlObject {
      name
      url
    }
  }
`;

export const TILES_FRAGMENT = `
  tiles {
    title
    subtitle: descriptionRaw
    footnote: footnoteReferenceSymbol
    ${GRAPHQL.URL_OBJECT}
    ${GRAPHQL.IMAGE_OBJECT}
  }
`;

export const INSURANCE_PAGE_LISTING_FRAGMENT = `
  rootUrl
  offer {
    description: descriptionRaw
  }
  termsAndConditionsUrl
  tileImage {
    caption
    ${GRAPHQL.IMAGE}
  }
`;

const INSURANCE_PAGE_FRAGMENT = `
  id: _id
  identifier {
    current
  }
  name
  descriptionRaw
  metadataObject {
    title: name
    description: descriptionRaw
  }
  brandCampaigns {
    sloganRaw
    descriptionRaw
    ${GRAPHQL.URL_OBJECT}
    ${GRAPHQL.IMAGE_OBJECT}
  }
  promotions {
    name: title,
    description: descriptionRaw
    release {
      startTime
      endTime
    }
  }
  offer {
    descriptionRaw
    footnoteLinkSymbol
    icon {
      ${GRAPHQL.IMAGE}
      caption
    }
  }
  valuePropositionGroup {
    title
    descriptionRaw
    valuePropositions {
      sloganRaw
      summaryRaw
      ${GRAPHQL.URL_OBJECT}
      ${GRAPHQL.IMAGE_OBJECT}
    }
  }
  ${FEATURES_AND_BENEFITS_FRAGMENT}
  ${TILES_FRAGMENT}
  formsAndGuides {
    heading
    documents {
      key: _key
      label
      file {
        asset {
          url
        }
      }
    }
    ${GRAPHQL.URL_OBJECT}
  }
  questions {
    question
    answerRaw
  }
  quickLinks {
    links {
      name
      url
    }
  }
  termsAndConditions {
    descriptionRaw
    footnotes {
      linkReferenceSymbol
      descriptionRaw
    }
    epilogueRaw
  }
  contactDetails {
    contact {
      name
      ${GRAPHQL.SLUG}
      title
      type
      action
      phoneNumber
      descriptionRaw
    }
    name
    ${GRAPHQL.URL_OBJECT}
    ${GRAPHQL.IMAGE_OBJECT}
  }
  award {
    name
    ${GRAPHQL.IMAGE_OBJECT}
    ${GRAPHQL.URL_OBJECT}
    summaryRaw
  }
  crossSell {
    name
    descriptionRaw
    tileImage {
      caption
      ${GRAPHQL.IMAGE}
    }
    rootUrl
  }
  productConfigGroup {
    name
    ${GRAPHQL.SLUG}
    descriptionRaw
    productOptions {
      name
      descriptionRaw
      ${GRAPHQL.IMAGE_OBJECT}
    }
  }
`;

const PRODUCT_TYPE_FRAGMENT = `
  name
  identifier {
    current
  }
`;

const PRODUCT_FRAGMENT = `
  _id
  identifier {
    current
  }
  name
  descriptionRaw
  includedServices {
    _id
    descriptionRaw
    name
  }
  productType {
    ${PRODUCT_TYPE_FRAGMENT}
  }
  ${GRAPHQL.URL_OBJECT}
`;

export const GET_INSURANCE_TYPE_QUERY = gql`
  query getInsuranceType($id: String) {
    allInsuranceType(where: { identifier: { current: { eq: $id } } }) {
      ${INSURANCE_PAGE_FRAGMENT}
    }
  }
`;

export const GET_PRODUCT_TYPES_BY_INSURANCE_TYPE_QUERY = gql`
  query getProductTypes($type: String) {
    allProductType(where: { insuranceType: { identifier: { current: { eq: $type } } } }) {
      _id
      name
      identifier {
        current
      }
    }
  }
`;

export const GET_PRODUCTS_BY_TYPE_QUERY = gql`
  query getProducts($productTypes: [String!]) {
    allProduct(where: { productType: { identifier: { current: { in: $productTypes } } } }) {
      ${PRODUCT_FRAGMENT}
    }
  }
`;

export const GET_BEST_SELLERS_QUERY = gql`
  query getBestSellers($productTypes: [String!]) {
    allBestSeller(where: { product: { productType: { identifier: { current: { in: $productTypes } } } }}) {
      _id
      product {
        ${PRODUCT_FRAGMENT}
      }
    }
  }
`;

export const GET_PRODUCT_OPTION_VALUE_QUERY = gql`
  query getProductOptionValue($id: String) {
    allProductOptionValue(where: { identifier: { current: { eq: $id } } }) {
      name
      ${GRAPHQL.SLUG}
      summaryRaw
      commonQuestions {
        title
        summaryRaw
        ${GRAPHQL.IMAGE_OBJECT}
        questions {
          name
          question
          answerRaw
        }
      }
      relevantAddOns {
        name
        descriptionRaw
        productOptions {
          name
          ${GRAPHQL.SLUG}
          descriptionRaw
          ${GRAPHQL.IMAGE_OBJECT}
        }
      }
      brandCampaigns {
        name
        sloganRaw
        descriptionRaw
        ${GRAPHQL.IMAGE_OBJECT}
        ${GRAPHQL.URL_OBJECT}
      }
      marketSegment {
        name
        identifier {
            current
        }
      }
    }
  }
`;
