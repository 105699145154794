import { gql } from '@apollo/client';

import { GRAPHQL } from '../constants';
import { INSURANCE_PAGE_LISTING_FRAGMENT } from '../../pages/insurance/queries';

export const GET_ALL_BANNER_QUERY = gql`
  query banners {
    allBanner {
      name
      subtitle
      identifier {
        current
      }
      listItems {
        name
        identifier {
          current
        }
        ${INSURANCE_PAGE_LISTING_FRAGMENT}
      }
    }
  }
`;

export const PERK_QUERY = `
  _updatedAt
  _id
  id: _id
  category
  descriptionTeaser
  ${GRAPHQL.SLUG}
  offeredBy {
    name
    logo {
      asset {
        _id
        path
      }
    }
    legalName
  }
  ${GRAPHQL.IMAGE_OBJECT}
  name
  ${GRAPHQL.RELEASE}
  slogan
  termsAndConditionsRaw
  ${GRAPHQL.URL_OBJECT}
`;

export const GET_ALL_PERKS_QUERY = gql`
  {
    allMemberPerk {
      ${PERK_QUERY}
    }
  }
`;

export const GET_CONTACT_QUERY = gql`
  query GetContact($identifier: String!) {
    allContact(where: { identifier: { current: { eq: $identifier } } }) {
      _id
      action
      descriptionRaw
      phoneNumber
    }
  }
`;

export const GET_HOSPITAL_GROUPS_QUERY = gql`
  query hospitalGroups {
    allHospitalGroup {
      _id
      name
      urlObject {
        name
        url
      }
      hospitals {
        title
        address
        outOfContractDate
        urlObject {
          name
          url
        }
      }
    }
  }
`;

export const GET_BLOG_POST = gql`
  query getArticle($identifier: String) {
    allArticle(where: { identifier: { current: { eq: $identifier } } }) {
      _updatedAt
      bodyRaw
      name
      descriptionRaw
      ${GRAPHQL.IMAGE_OBJECT}
      ${GRAPHQL.URL_OBJECT}
      termsAndConditions {
        descriptionRaw
        footnotes {
          linkReferenceSymbol
          descriptionRaw
        }
      }
      tags {
        name
      }
    }
  }
`;

export const GET_BLOG_IDENTIFIERS = gql`
  query {
    allArticle {
      identifier {
        current
      }
      insuranceType {
        identifier {
          current
        }
      }
    }
  }
`;
