import { List, P, Small, Strong } from '@ahmdigital/ui';
import React from 'react';

import { StyledSmall } from './shared';

export default {
  intro: {
    description: (
      <>
        <P>
          You can’t plan for an accident, but you can plan for getting hospital cover where we have your back for
          Accidents<StyledSmall>#</StyledSmall>. And, you won’t have to pay an excess for these Accidents - double the
          win!
        </P>
        <Strong>Features</Strong>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>
              Accident Override<StyledSmall>#</StyledSmall>
            </Strong>
          </li>
          <li>
            <Strong>No excess for Accidents under Accident Override^^</Strong>
          </li>
          <li>
            <Strong>No excess for dependents</Strong>
          </li>
          <li>
            <Strong>Unlimited Medically Necessary Ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    disclaimer: (
      <>
        <Small>
          <StyledSmall>#</StyledSmall>We will pay benefits towards the initial and ongoing hospital treatment that
          resulted from an Accident for all services that are listed as excluded and restricted on your cover. An
          Accident refers to an unplanned or unforeseen event resulting in bodily injury that requires immediate medical
          treatment in a hospital.{' '}
        </Small>
        <br />
        <Small>
          ^^If you are admitted to hospital for an Accident, where possible we’ll waive the excess upfront. However, due
          to the way we receive claims for hospitalisations relating to Accidents, the hospital may require you to pay
          the excess on the day of the admission. We’ll then reimburse this amount, subject to eligibility of the
          waiver. The excess will only be waived for the first admission each membership year in relation to a
          non-compensatable Accident.{' '}
        </Small>
        <br />
        <Small>
          ^Transport to the nearest hospital provided by an ahm approved road, sea or air Ambulance provider that’s able
          to provide the level of care you medically need. Some ahm products limit the number of Ambulance services
          we’ll pay benefits towards per financial year. Check your product information to see if any limits apply. Your
          Daily Charge and/or Excess doesn’t apply to Ambulance claims.{' '}
        </Small>
      </>
    ),
  },
};
