import advancedHospitalGold from './advanced-hospital-gold';
import ambulanceAndAccidentBasic from './ambulance-and-accident-basic';
import ambulanceBasic from './ambulance-basic';
import black50SaverExtras from './black-50-saver-extras';
import black60Extras from './black-60-extras';
import black70BoostExtras from './black-70-boost-extras';
import black70Extras from './black-70-extras';
import choosable50 from './choosable-50';
import choosable60 from './choosable-60';
import classicExtras from './classic-extras';
import classicFlexiSilverPlus from './classic-flexi-silver-plus';
import classicSilverPlus from './classic-silver-plus';
import coreBronzePlus from './core-bronze-plus';
import coreHospitalBasicPlus from './core-hospital-basic-plus';
import coreSilverPlus from './core-silver-plus';
import deluxeFlexiSilverPlus from './deluxe-flexi-silver-plus';
import deluxeSilverPlus from './deluxe-silver-plus';
import essentialsBasicPlus from './essentials-basic-plus';
import essentialsFlexiBasicPlus from './essentials-flexi-basic-plus';
import essentialsSilverPlus from './essentials-silver-plus';
import familyExtras from './family-extras';
import lifestyleExtras from './lifestyle-extras';
import liteBronzePlus from './lite-bronze-plus';
import liteFlexiBronzePlus from './lite-flexi-bronze-plus';
import simpleBronzePlus from './simple-bronze-plus';
import starterBasic from './starter-basic';
import starterBronze from './starter-bronze';
import starterFlexiBasic from './starter-flexi-basic';
import superExtras from './super-extras';
import valueExtras from './value-extras';

export default {
  'advanced-hospital-gold': advancedHospitalGold,
  'ambulance-and-accident-basic': ambulanceAndAccidentBasic,
  'ambulance-basic': ambulanceBasic,
  'black-50-saver-extras': black50SaverExtras,
  'black-60-extras': black60Extras,
  'black-70-boost-extras': black70BoostExtras,
  'black-70-extras': black70Extras,
  'choosable-50-teeth': choosable50,
  'choosable-60-teeth-eyes': choosable60,
  'choosable-60-teeth-eyes-muscle-bone': choosable60,
  'choosable-60-teeth-eyes-wellbeing': choosable60,
  'choosable-60-teeth-eyes-wellbeing-muscle-bone': choosable60,
  'choosable-60-teeth-muscle-bone': choosable60,
  'choosable-60-teeth-wellbeing': choosable60,
  'choosable-60-teeth-wellbeing-muscle-bone': choosable60,
  'classic-extras': classicExtras,
  'classic-flexi-silver-plus': classicFlexiSilverPlus,
  'classic-silver-plus': classicSilverPlus,
  'core-bronze-plus': coreBronzePlus,
  'core-hospital-basic-plus': coreHospitalBasicPlus,
  'core-silver-plus': coreSilverPlus,
  'deluxe-flexi-silver-plus': deluxeFlexiSilverPlus,
  'deluxe-silver-plus': deluxeSilverPlus,
  'essentials-basic-plus': essentialsBasicPlus,
  'essentials-flexi-basic-plus': essentialsFlexiBasicPlus,
  'essentials-silver-plus': essentialsSilverPlus,
  'family-extras': familyExtras,
  'lifestyle-extras': lifestyleExtras,
  'lite-bronze-plus': liteBronzePlus,
  'lite-flexi-bronze-plus': liteFlexiBronzePlus,
  'simple-bronze-plus': simpleBronzePlus,
  'starter-basic': starterBasic,
  'starter-bronze': starterBronze,
  'starter-flexi-basic': starterFlexiBasic,
  'super-extras': superExtras,
  'value-extras': valueExtras,
};
