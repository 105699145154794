import { Small } from '@ahmdigital/ui';
import React from 'react';
import styled from 'styled-components';

export const ambulanceDisclaimerTasQld = (
  <Small>^TAS/QLD State schemes cover ambulance services for residents of those States.</Small>
);

export const StyledSmall = styled(Small)`
  vertical-align: top;
`;
